<template>
  <div class="footer-container">
    <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #333">
      粤ICP备2021020587号
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer-container {
  position: fixed;
  right: 24px;
  bottom: 24px;
  font-size: 14px;
}
</style>
