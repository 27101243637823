<template>
  <div class="home">
    <HelloWorld />
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Home',
  components: {
    HelloWorld,
    Footer,
  },
});
</script>
