
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HelloWorld',
  data() {
    return {
      msg: 'Hello Semporna',
    };
  },
});
